// constants.js
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUPSUCCESS = "SIGNUPSUCCESS";
export const SIGNUPFAIL = "SIGNUPFAIL";
export const SIGNUPREQUEST = "SIGNUPREQUEST";

export const FETCHALLUSER = "FETCHALLUSER";
export const SUBJECTFETCHED = "SUBJECTFETCHED";
export const TIMEFETCHED = "TIMEFETCHED";
export const DIFFICULTYFETCHED = "DIFFICULTYFETCHED";
export const NEWTIMECREATED = "NEWTIMECREATED";
export const NEWDIFFICULTYCREATED = "NEWDIFFICULTYCREATED";
export const NEWSUBJECTCREATED = "NEWSUBJECTCREATED";
export const NEWSUBJECTRECET = "NEWSUBJECTRECET";
export const SENDINGDATA = "SENDINGDATA";
export const RETEINGRESULT = "RETEINGRESULT";
export const RESULTFETCHED = "RESULTFETCHED";
export const RESULTRESET = "RESULTRESET";
